/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import styles from './styles.module.scss';
import { PORTFOLIO_ITEMS_PER_PAGE } from '../../constants';
import { ShowMoreButton } from '../../components/main/ShowMoreButton';
import { t } from '../../i18n';
import { ICaseQuery } from '../../queries/cases/types';
import { CaseCell as SmallCaseCell } from '../../components/case/common/SmallCaseCell';
import { BigCaseCell } from '../../components/case/common/BigCaseCell';
import type { ICaseQueryEdge } from '../../queries/cases/types';

type IProps = PageProps<
  ICaseQuery,
  { locale: string; tag: string; page: number }
>;

type sortedProjectsType = (ICaseQueryEdge['node'] & { index: number })[];

const CASES_GRID_LENGTH = 3;

const CasePage: FC<IProps> = ({
  data,
  pageContext: { locale = 'ru', tag = '', page = 0 },
}) => {
  const [showNodes, setShowNodes] = useState(PORTFOLIO_ITEMS_PER_PAGE);

  const projects = useMemo(
    () =>
      data.projects.edges
        .map((edge) => edge.node)
        .filter(
          (node) =>
            (!tag ||
              (node.frontmatter.tags && node.frontmatter.tags.includes(tag))) &&
            node.frontmatter.status !== 'inRevision'
        ),
    [locale, data.projects, tag]
  );

  const onScroll = useCallback(() => {
    if (
      document.documentElement.scrollHeight -
        window.scrollY -
        window.innerHeight >
        200 ||
      projects.length <= showNodes - 1
    )
      return;

    setShowNodes(showNodes + PORTFOLIO_ITEMS_PER_PAGE);
  }, [projects, showNodes, setShowNodes]);

  const setScroll = () => {
    if (
      window.location.pathname === '/cases/' ||
      window.location.pathname === '/ru/cases/'
    ) {
      localStorage.setItem('scroll', String(window.pageYOffset));
    }
  };

  useEffect(() => {
    const { scroll } = localStorage;
    if (scroll) {
      window.scroll(0, +scroll);
    }
    const history = window.location.pathname;
    const scrollContainer = document.getElementById('scrollContainer');
    if (history === '/cases/' || history === '/ru/cases/') {
      if (scrollContainer) {
        window.addEventListener('scroll', () => {
          setScroll();
        });
      }
    }
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, [onScroll]);

  // setTimeout will run after TransitionLink animation
  useEffect(() => {
    setTimeout(onScroll, 750);
  }, [onScroll, projects]);

  const paginatedProjects = useMemo(
    () => projects.slice(page * PORTFOLIO_ITEMS_PER_PAGE),
    [projects, page, showNodes]
  );

  const sortedProjects = useMemo(
    () =>
      paginatedProjects.reduce(
        (previousValue, currentValue) =>
          currentValue.frontmatter.previewImage.type === 'big'
            ? {
                ...previousValue,
                big: [
                  ...previousValue.big,
                  { index: previousValue.big.length, ...currentValue },
                ] as sortedProjectsType,
              }
            : {
                ...previousValue,
                small: [
                  ...previousValue.small,
                  { index: previousValue.small.length, ...currentValue },
                ] as sortedProjectsType,
              },
        { small: [] as sortedProjectsType, big: [] as sortedProjectsType }
      ),
    []
  );

  let projectsGrid = [] as sortedProjectsType[];

  const getSliceStart = (index: number) =>
    !!projectsGrid.length &&
    projectsGrid?.[index - 1]?.[projectsGrid?.[index - 1]?.length - 1]
      ?.frontmatter.previewImage.type === 'small'
      ? projectsGrid?.[index - 1]?.[projectsGrid?.[index - 1]?.length - 1]
          ?.index + 1
      : index * 2;

  const getSliceEnd = (index: number) =>
    !!projectsGrid.length &&
    projectsGrid?.[index - 1]?.[projectsGrid?.[index - 1]?.length - 1]
      ?.frontmatter.previewImage.type === 'small'
      ? projectsGrid?.[index - 1]?.[projectsGrid?.[index - 1]?.length - 1]
          ?.index +
        2 +
        1
      : index * 2 + 2;

  for (
    let i = 0;
    i < Math.ceil(paginatedProjects.length / CASES_GRID_LENGTH);
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    projectsGrid = [
      ...projectsGrid,
      [
        ...sortedProjects.small.slice(getSliceStart(i), getSliceEnd(i)),
        sortedProjects.big[i] ?? sortedProjects.small[getSliceEnd(i)],
      ],
    ];
  }

  return (
    <MainLayout locale={locale} title="menu.portfolio">
      <div id="scrollContainer" className={classnames('content', styles.wrap)}>
        {!!page && page >= 1 && !tag && (
          <div className={styles.show_more}>
            <ShowMoreButton url="/case" page={page - 1}>
              {t('portfolio.show_previous_projects')}
            </ShowMoreButton>
          </div>
        )}

        {projectsGrid.length &&
          projectsGrid.map((arr, i) => (
            <div
              key={i}
              className={classnames(styles.grid, {
                [styles.small]: i > sortedProjects.big.length - 1,
              })}
            >
              {arr.map(
                (item) =>
                  item &&
                  (item.frontmatter.previewImage.type !== 'big' ? (
                    <SmallCaseCell
                      from="/cases"
                      item={item}
                      key={item.id}
                      isReversed={!!(i % 2)}
                    />
                  ) : (
                    <BigCaseCell
                      from="/cases"
                      item={item}
                      key={item.id}
                      isReversed={!!(i % 2)}
                    />
                  ))
              )}
            </div>
          ))}

        {projects.length >= PORTFOLIO_ITEMS_PER_PAGE * (page + 1) &&
          projects.length >= showNodes &&
          !tag && (
            <div className={styles.show_more}>
              <ShowMoreButton url="/cases" page={page + 1}>
                {t('portfolio.show_more')}
              </ShowMoreButton>
            </div>
          )}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  {
    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/cases/projects/" } }
      sort: {
        fields: [frontmatter___status, frontmatter___date]
        order: [ASC, DESC]
      }
    ) {
      ...CaseFields
    }
  }
`;

export default CasePage;
